var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-title"},[_c('div',{staticClass:"header-grid"},[_c('div',{staticClass:"header-gridItem"},[_c('img',{staticClass:"tokamak-logo",staticStyle:{"width":"250px","height":"30px","cursor":"pointer"},attrs:{"src":require("@/assets/tn_logo.svg")},on:{"click":function($event){_vm.$route.path !== '/' ? _vm.$router.push({ path: '/' }) : ''}}})]),_c('div',{staticClass:"header-gridItem",staticStyle:{"justify-content":"center"}},[_c('div',{staticClass:"menu"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"dot",class:{ visible: _vm.$route.path.includes('solutions') }}),_c('router-link',{staticClass:"menu-item",class:{
                selected: _vm.$route.path.includes('solutions'),
              },attrs:{"to":'/solutions'},on:{"click":function($event){_vm.selectedMenu = 'solutions'}}},[_vm._v(" Solutions ")])],1),_c('div',{staticClass:"item"},[_c('div',{staticClass:"dot",class:{ visible: _vm.showDrop === 'services' }}),_c('div',{staticClass:"menu-item",class:{
                selected: _vm.showDrop === 'services',
              },on:{"mouseover":function($event){return _vm.openDropDown('services')},"mouseleave":function($event){_vm.showDrop = ''}}},[_vm._v(" Services "),_c('div',{on:{"mouseleave":function($event){_vm.showDrop = ''}}},[_c('transition',{attrs:{"name":"fade"}},[_c('services-dropdown',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDrop === 'services'),expression:"showDrop === 'services'"}]})],1)],1)])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"dot",class:{ visible: _vm.showDrop === 'developers' }}),_c('div',{staticClass:"menu-item",class:{
                selected: _vm.showDrop === 'developers',
              },on:{"mouseover":function($event){return _vm.openDropDown('developers')},"mouseleave":function($event){_vm.showDrop = ''}}},[_vm._v(" Developer "),_c('div',{on:{"mouseleave":function($event){_vm.showDrop = ''}}},[_c('transition',{attrs:{"name":"fade"}},[_c('developers-dropDown',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDrop === 'developers'),expression:"showDrop === 'developers'"}]})],1)],1)])]),_c('div',{staticClass:"item"},[_c('button',{staticClass:"menu-item",staticStyle:{"height":"24px","margin-bottom":"-6px"},on:{"click":function($event){return _vm.click(
                  'https://tokamak.notion.site/Tokamak-Network-Grant-Program-f2384b458ea341a0987c7e73a909aa21'
                )}}},[_vm._v(" Grant ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"dot",class:{ visible: _vm.$route.path.includes('about') }}),_c('router-link',{staticClass:"menu-item",class:{
                selected: _vm.$route.path.includes('about'),
              },attrs:{"to":'/about'}},[_vm._v(" About ")])],1)])]),_c('div',{staticClass:"header-gridItem",staticStyle:{"justify-content":"flex-end"}},[_c('div',{staticClass:"language",staticStyle:{"display":"flex"}},[_c('div',{staticClass:"lang",staticStyle:{"cursor":"pointer"},style:(_vm.currentFlag === 'EN' ? { color: '#246fed' } : {}),on:{"click":function($event){return _vm.languageItemClicked(_vm.supportedLanguages[0])}}},[_vm._v(" EN ")]),_c('div',{staticStyle:{"margin":"0 3px 0 3px"}},[_vm._v("|")]),_c('div',{staticClass:"lang",staticStyle:{"cursor":"pointer"},style:(_vm.currentFlag === 'KR' ? { color: '#246fed' } : {}),on:{"click":function($event){return _vm.languageItemClicked(_vm.supportedLanguages[1])}}},[_vm._v(" KR ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }